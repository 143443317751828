import Vue from 'vue'
import App from './App.vue'
import router from './router'
import "./assets/css/reset.css"
import "./assets/css/common.css"
import "./assets/css/index.css"
import '@/assets/js/rem.js'
// element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false

// 路由拦截
import RouterInterceptor from "./routerInterceptor"
RouterInterceptor(router)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
