import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('./views/Home.vue'),
    children: [
      {
        path: '/',
        component: () => import('./components/Index.vue'),
        meta: {
          title: '慧言科技'
        }
      },
      {
        path: '/diting',
        component: () => import('./components/Diting.vue'),
        meta: {
          title: '产品-海河谛听言语交互大模型'
        }
      },
      {
        path: '/ai',
        component: () => import('./components/AI.vue'),
        meta: {
          title: '产品-慧言AI平台'
        }
      },
      {
        path: '/meeting',
        component: () => import('./components/Meeting.vue'),
        meta: {
          title: '产品-智能会议系统'
        }
      },
      {
        path: '/privatization',
        component: () => import('./components/Privatization.vue'),
        meta: {
          title: '产品-智能语音私有化部署'
        }
      },
      {
        path: '/sdk',
        component: () => import('./components/SDK.vue'),
        meta: {
          title: '产品-智能语音移动端离线SDK'
        }
      },
      {
        path: '/nmt',
        component: () => import('./components/Nmt.vue'),
        meta: {
          title: '产品-智能翻译机'
        }
      },
      {
        path: '/screen',
        component: () => import('./components/Screen.vue'),
        meta: {
          title: '会议大屏解决方案'
        }
      },
      {
        path: '/edu',
        component: () => import('./components/Edu.vue'),
        meta: {
          title: '智慧教育解决方案'
        }
      },
      {
        path: '/interpreting',
        component: () => import('./components/Interpreting.vue'),
        meta: {
          title: '智慧同传解决方案'
        }
      },
      {
        path: '/ppp',
        component: () => import('./components/Ppp.vue'),
        meta: {
          title: '公检法场景解决方案'
        }
      },
      {
        path: '/introduction',
        component: () => import('./components/Introduction.vue'),
        meta: {
          title: '公司简介'
        }
      },
      {
        path: '/news',
        component: () => import('./components/News.vue'),
        meta: {
          title: '新闻中心'
        }
      },
      {
        path: '/career',
        component: () => import('./components/Career.vue'),
        meta: {
          title: '加入我们'
        }
      },
      {
        path: '/contact',
        component: () => import('./components/Contact.vue'),
        meta: {
          title: '联系我们'
        }
      },
    ]
  }
]

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
})

router.afterEach(()=>{
  window.scrollTo(0,0);
})

export default router;
