<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'app',
  mounted () {
    if (window.innerWidth > 900) {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?64904e9ec9693ffb5ec3e899886b430b";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    }
  }
}
</script>
<style scoped>
#app {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
